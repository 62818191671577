/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { notification, Layout, Button, Input, Modal, Popconfirm, Empty, Table, Popover, Switch } from 'antd';
import { ReactComponent as Magnifying } from '../../icons/magnifying-glas.svg';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';
import { SERVER_URL } from '../../config';
import HeaderComponent from '../../components/base/HeaderComponent';
import PersonnelForm from '../../components/forms/PersonnelForm';

const Personnels = () => {
  const currentuser = useContext(UserContext);
  const [personnels, setPersonnels] = useState();
  const [branches, setBranches] = useState([]);
  const [personnelForEdit, setPersonnelForEdit] = useState(null);
  const [newPersonnel, setNewPersonnel] = useState(null);
  const [qoolections, setQoolections] = useState([]);

  const getPersonnels = async (search = null) => {
    try {
      const response =
        search && search.length > 1
          ? await Axios.get(
              `${SERVER_URL}/patients?filter=${JSON.stringify({
                $and: [
                  { type: 'personnel' },
                  {
                    $or: [
                      { deviceId: { $regex: search, $options: 'i' } },
                      { token: { $regex: search, $options: 'i' } },
                    ],
                  },
                ],
              })}`,
              { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
            )
          : await Axios.get(`${SERVER_URL}/patients?filter=${JSON.stringify({ type: 'personnel' })}`, {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            });
      if (response?.data?.items) setPersonnels(response.data.items);
      else setPersonnels([]);
    } catch (error) {
      console.log('Error getting personnels:', error?.response?.data?.message);
    }
  };

  const getBranches = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/client-branches`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data?.items) setBranches(response?.data?.items);
      else setBranches([]);
    } catch (error) {
      console.log('Error getting branches:', error?.response?.data?.message);
    }
  };

  const getPublishedQoolections = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/published-qoolections-for-patient`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data?.items) {
        setQoolections(response?.data?.items);
      } else setQoolections([]);
    } catch (error) {
      console.log('Error getting published qoolections:', error?.response?.data?.message);
    }
  };

  const getPersonnelForEdit = async (id) => {
    try {
      const response = await Axios.get(`${SERVER_URL}/patients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data) setPersonnelForEdit(response.data);
      else setPersonnelForEdit(null);
    } catch (error) {
      console.log('Error getting personnel for edit:', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getBranches();
    getPublishedQoolections();
    getPersonnels();
  }, [setPersonnels, setPersonnelForEdit, setNewPersonnel, setQoolections]);

  const deletePersonnelHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/patients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({ message: 'Personnel deleted successfully', placement: 'topLeft', duration: 2 });
      getPersonnels();
    } catch (error) {
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const addAndEditPersonnelHandler = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/patients` : `${SERVER_URL}/patients/${personnelForEdit._id}`;
    try {
      await Axios[method](
        route,
        { ...formData, type: 'personnel' },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: `Personnel ${isNew ? 'added' : 'updated'} successfully`,
        placement: 'topLeft',
        duration: 2,
      });
      setPersonnelForEdit(null);
      setNewPersonnel(null);
      getPersonnels();
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with create/update.',
        placement: 'topLeft',
        duration: 2,
      });
    }
  };

  const handleSetDefaultSiteContact = async (personnelId, siteId, isDefaultSiteContact) => {
    try {
      await Axios.put(
        `${SERVER_URL}/personnels-set-default-site-contact`,
        { personnelId, siteId, isDefaultSiteContact },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({ message: 'Default site contact set', placement: 'topLeft', duration: 2 });
      getPersonnels();
    } catch (error) {
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const copyToClipboardHandler = async (link) => {
    await navigator.clipboard.writeText(link);
  };

  const handleSearch = (e) => getPersonnels(e);

  const printQR = async () => {
    const divContents = await document.getElementById('qr_code').innerHTML;
    const a = window.open('', '', 'height=1024, width=768');
    a.document.write('<html>');
    a.document.write('<body >');
    a.document.write(divContents);
    a.document.write('</body></html>');
    a.document.close();
    a.print();
  };

  const columns = [
    {
      title: 'User ID',
      dataIndex: 'deviceId',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Site',
      dataIndex: ['site', 'branchName'],
    },
    {
      title: 'Qoolection',
      dataIndex: ['qoolection', 'title'],
    },
    {
      title: 'Default Site Contact',
      dataIndex: 'isDefaultSiteContact',
      render: (text, record) => (
        <Switch
          checked={record.isDefaultSiteContact}
          onChange={(e) => handleSetDefaultSiteContact(record._id, record.site._id, e)}
        />
      ),
    },

    {
      title: 'QR code',
      render: (text, record) => (
        <Popover
          content={
            record.QRcode && (
              <div>
                <div id='qr_code'>
                  <img style={{ size: 'a5', margin: '0mm' }} alt='QR code' src={record.QRcode} />
                </div>
                <div
                  style={{ cursor: 'pointer', margin: '5px 0px 15px 0px' }}
                  onClick={() => copyToClipboardHandler(record.pairingURL)}
                >
                  {record?.pairingURL}
                </div>
                <Button onClick={() => printQR()}>PRINT</Button>
              </div>
            )
          }
          title='QR code'
        >
          {record.QRcode && <img height='35px' width='35px' alt='QR code' src={record.QRcode} />}
        </Popover>
      ),
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              placement='left'
              title={`This will delete personnel`}
              onConfirm={() => deletePersonnelHandler(record._id)}
              okText='Ok'
              cancelText='Cancel'
            >
              <Trash title={`Delete personnel`} />
            </Popconfirm>
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => getPersonnelForEdit(record._id)}
              title={`Edit personnel`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Layout className='manage-layout'>
      <div
        style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        <HeaderComponent text='Manage personnels' />
        <div className='teams-content'>
          <div className='teams-container'>
            <div className='teams-upper-part'>
              <div className='left-part'>
                <Input
                  className='inppy'
                  placeholder='Search'
                  onChange={(e) => handleSearch(e.target.value)}
                  prefix={<Magnifying style={{ marginRight: '5px' }} />}
                />
                <Button className='butty' type='primary' onClick={() => setNewPersonnel(true)}>
                  Add Personnel
                </Button>
              </div>
            </div>

            <div className='table-manage'>
              <Table
                size='middle'
                bordered
                dataSource={personnels}
                columns={columns}
                locale={{ emptyText: <Empty description='No Personnels, please add personnel.' /> }}
                rowKey='_id'
                pagination={{
                  defaultPageSize: 20,
                  position: 'bottom',
                  showSizeChanger: false,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  hideOnSinglePage: false,
                }}
              />
            </div>
          </div>
        </div>
        {personnelForEdit && (
          <div>
            <Modal open={personnelForEdit} onCancel={() => setPersonnelForEdit(null)} footer={null} centered={true}>
              <div style={{ padding: '8px' }}>
                <div>
                  <PersonnelForm
                    isNew={false}
                    data={personnelForEdit}
                    onSubmit={addAndEditPersonnelHandler}
                    branches={branches}
                    qoolections={qoolections}
                    companyPrefix={currentuser?.data?.client?.companyPrefix}
                  />
                </div>
              </div>
            </Modal>
          </div>
        )}
        {newPersonnel && (
          <div>
            <Modal open={newPersonnel} onCancel={() => setNewPersonnel(null)} footer={null} centered={true}>
              <div style={{ padding: '10px 0px 40px 0px' }}>
                <div>
                  <PersonnelForm
                    isNew={true}
                    data={personnelForEdit}
                    onSubmit={addAndEditPersonnelHandler}
                    branches={branches}
                    qoolections={qoolections}
                    companyPrefix={currentuser?.data?.client?.companyPrefix}
                  />
                </div>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Personnels;
