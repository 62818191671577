/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { notification, Layout, Button, Input, Modal, Tag, Popconfirm, Popover, Empty, Table } from 'antd';
import { ReactComponent as Magnifying } from '../../icons/magnifying-glas.svg';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';
import { ReactComponent as Download } from '../../icons/download.svg';
import { SERVER_URL } from '../../config';
import HeaderComponent from '../../components/base/HeaderComponent';
import StudyForm from '../../components/forms/StudyForm';
import { saveAs } from 'file-saver';

const Studies = (props) => {
  const currentuser = useContext(UserContext);
  const [studies, setStudies] = useState();
  const [studyForEdit, setStudyForEdit] = useState(null);
  const [newStudy, setNewStudy] = useState(null);
  const [downloadPopoverId, setDownloadPopoverId] = useState(null);
  const currentDownload = useRef();

  const getStudies = async (search = null) => {
    const response =
      search && search.length > 1
        ? await Axios.get(
            `${SERVER_URL}/studies?filter=${JSON.stringify({ name: { $regex: search, $options: 'i' } })}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          )
        : await Axios.get(`${SERVER_URL}/studies`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });
    response?.data?.items ? setStudies(response.data.items) : setStudies([]);
  };

  const getStudyForEdit = async (id) => {
    const response = await Axios.get(`${SERVER_URL}/studies/${id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    response?.data ? setStudyForEdit(response.data) : setStudyForEdit(null);
  };

  const getStudy = async (id, type = 'excel') => {
    const { data } = await Axios.get(`${SERVER_URL}/export-study-data-${type}/${id}`, {
      withCredentials: false,
      responseType: 'blob',
      timeout: 30000,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    saveAs(data, `Study ${id}`);
  };

  useEffect(() => {
    getStudies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setStudies, setStudyForEdit, setNewStudy]);

  const deleteStudyHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/studies/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({ message: 'Study deleted successfully', placement: 'topLeft', duration: 2 });
      getStudies();
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message || 'Problem with delete.',
        placement: 'topLeft',
        duration: 2,
      });
    }
  };

  const addAndEditStudyHandler = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/studies` : `${SERVER_URL}/studies/${studyForEdit._id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `Study ${isNew ? 'added' : 'updated'} successfully`,
        placement: 'topLeft',
        duration: 2,
      });
      setStudyForEdit(null);
      setNewStudy(null);
      getStudies();
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message || 'Problem with create/edit.',
        placement: 'topLeft',
        duration: 2,
      });
    }
  };

  const handleSearch = (e) => {
    getStudies(e);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Therapeutic Area',
      dataIndex: 'therapeuticArea',
      render: (text, record) =>
        record.therapeuticArea.map((ta) => (
          <Tag style={{ backgroundColor: '#E5f9c6' }} key={ta._id + 110}>
            {ta.name}
          </Tag>
        )),
    },
    {
      title: 'Conditions',
      dataIndex: 'therapeuticAreaCondition',
      render: (text, record) =>
        record.therapeuticAreaCondition.map((cond) => (
          <Tag style={{ backgroundColor: '#C6e2de' }} key={cond._id + 120}>
            {cond.name}
          </Tag>
        )),
    },
    {
      title: 'Steps (follow ups)',
      dataIndex: 'studySteps',
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              placement='left'
              title={`This will delete study`}
              onConfirm={() => deleteStudyHandler(record._id)}
              okText='Ok'
              cancelText='Cancel'
            >
              <Trash title={`Delete study`} />
            </Popconfirm>
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => getStudyForEdit(record._id)}
              title={`Edit study`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popover
              content={
                <>
                  <Button
                    className='butty'
                    type='primary'
                    style={{ fontSize: '12px', margin: '5px' }}
                    onClick={() => {
                      getStudy(record._id, 'excel');
                      setDownloadPopoverId(null);
                    }}
                  >
                    XLS
                  </Button>
                  <Button
                    className='butty'
                    type='primary'
                    style={{ fontSize: '12px', margin: '5px' }}
                    onClick={() => {
                      getStudy(record._id, 'json');
                      setDownloadPopoverId(null);
                    }}
                  >
                    JSON
                  </Button>
                </>
              }
              title='Download'
              trigger='click'
              open={downloadPopoverId === record._id}
              ref={currentDownload}
              onOpenChange={(newOpen) => {
                setDownloadPopoverId(newOpen ? record._id : null);
              }}
            >
              <Download
                title={`Download`}
                style={{ textDecoration: 'none', color: '#eeeeee', width: '18px', height: '18px' }}
              />
            </Popover>
          </div>
        </div>
      ),
    },
  ];

  return (
    <Layout className='manage-layout'>
      <div
        style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        <HeaderComponent text='Manage studies' />
        <div className='teams-content'>
          <div className='teams-container'>
            <div className='teams-upper-part'>
              <div className='left-part'>
                <Input
                  className='inppy'
                  placeholder='Search'
                  onChange={(e) => handleSearch(e.target.value)}
                  prefix={<Magnifying style={{ marginRight: '5px' }} />}
                />
                <Button className='butty' type='primary' onClick={() => setNewStudy(true)}>
                  Add new study
                </Button>
              </div>
            </div>

            <div className='table-manage'>
              <Table
                size='middle'
                bordered
                dataSource={studies}
                columns={columns}
                locale={{ emptyText: <Empty description='No Studies, please add study.' /> }}
                rowKey='_id'
                pagination={{
                  defaultPageSize: 20,
                  position: 'bottom',
                  showSizeChanger: false,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  hideOnSinglePage: false,
                }}
              />
            </div>
          </div>
        </div>
        {studyForEdit && (
          <div>
            <Modal open={studyForEdit} onCancel={() => setStudyForEdit(null)} footer={null} centered={true}>
              <div style={{ padding: '8px' }}>
                <div>
                  <StudyForm
                    isNew={false}
                    data={studyForEdit}
                    onSubmit={addAndEditStudyHandler}
                    SERVER_URL={SERVER_URL}
                  />
                </div>
              </div>
            </Modal>
          </div>
        )}
        {newStudy && (
          <div>
            <Modal open={newStudy} onCancel={() => setNewStudy(null)} footer={null} centered={true}>
              <div style={{ padding: '10px 0px 40px 0px' }}>
                <div>
                  <StudyForm
                    isNew={true}
                    data={studyForEdit}
                    onSubmit={addAndEditStudyHandler}
                    SERVER_URL={SERVER_URL}
                  />
                </div>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Studies;
