/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { notification, Layout, Button, Input, Modal, Popconfirm, Empty, Table, Popover } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { ReactComponent as Magnifying } from '../../icons/magnifying-glas.svg';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';
import { SERVER_URL } from '../../config';
import HeaderComponent from '../../components/base/HeaderComponent';
import PatientForm from '../../components/forms/PatientForm';

const Patients = () => {
  const currentuser = useContext(UserContext);
  const [patients, setPatients] = useState();
  const [studies, setStudies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [patientForEdit, setPatientForEdit] = useState(null);
  const [newPatient, setNewPatient] = useState(null);
  const [qoolections, setQoolections] = useState([]);

  const getPatients = async (search = null) => {
    try {
      const response =
        search && search.length > 1
          ? await Axios.get(
              `${SERVER_URL}/patients?filter=${JSON.stringify({
                $and: [
                  { type: 'patient' },
                  {
                    $or: [
                      { deviceId: { $regex: search, $options: 'i' } },
                      { token: { $regex: search, $options: 'i' } },
                    ],
                  },
                ],
              })}`,
              { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
            )
          : await Axios.get(`${SERVER_URL}/patients?filter=${JSON.stringify({ type: 'patient' })}`, {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            });
      if (response?.data?.items) setPatients(response.data.items);
      else setPatients([]);
    } catch (error) {
      console.log('Error getting patients:', error?.response?.data?.message);
    }
  };

  const getBranches = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/client-branches`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data?.items) setBranches(response?.data?.items);
      else setBranches([]);
    } catch (error) {
      console.log('Error getting branches:', error?.response?.data?.message);
    }
  };

  const getStudies = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/studies`, {
        withCredentials: false,
        headers: { authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data?.items) setStudies(response.data.items);
      else setStudies([]);
    } catch (error) {
      console.log('Error getting studies:', error?.response?.data?.message);
    }
  };

  const getPublishedQoolections = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/published-qoolections-for-patient`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data?.items) {
        setQoolections(response?.data?.items);
      } else setQoolections([]);
    } catch (error) {
      console.log('Error getting published qoolections:', error?.response?.data?.message);
    }
  };

  const getPatientForEdit = async (id) => {
    try {
      const response = await Axios.get(`${SERVER_URL}/patients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data) setPatientForEdit(response.data);
      else setPatientForEdit(null);
    } catch (error) {
      console.log('Error getting patient for edit:', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getBranches();
    getStudies();
    getPublishedQoolections();
    getPatients();
  }, [setPatients, setPatientForEdit, setNewPatient, setQoolections]);

  const deletePatientHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/patients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({ message: 'Patient deleted successfully', placement: 'topLeft', duration: 2 });
      getPatients();
    } catch (error) {
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const addAndEditPatientHandler = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/patients` : `${SERVER_URL}/patients/${patientForEdit._id}`;
    try {
      await Axios[method](
        route,
        { ...formData, type: 'patient' },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: `Patient ${isNew ? 'added' : 'updated'} successfully`,
        placement: 'topLeft',
        duration: 2,
      });
      setPatientForEdit(null);
      setNewPatient(null);
      getPatients();
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with create/update.',
        placement: 'topLeft',
        duration: 2,
      });
    }
  };

  const copyToClipboardHandler = async (link) => {
    await navigator.clipboard.writeText(link);
  };

  const handleSearch = (e) => getPatients(e);

  const printQR = async () => {
    const divContents = await document.getElementById('qr_code').innerHTML;
    const a = window.open('', '', 'height=1024, width=768');
    a.document.write('<html>');
    a.document.write('<body >');
    a.document.write(divContents);
    a.document.write('</body></html>');
    a.document.close();
    a.print();
  };

  const columns = [
    {
      title: 'Device',
      dataIndex: 'deviceId',
    },
    {
      title: 'Token',
      dataIndex: 'token',
    },
    {
      title: 'Study',
      dataIndex: ['study', 'name'],
    },
    {
      title: 'Site',
      dataIndex: ['site', 'branchName'],
    },
    {
      title: 'Qoolection',
      dataIndex: ['qoolection', 'title'],
    },
    {
      title: 'QR Code',
      render: (text, record) => (
        <Popover
          content={
            <div>
              <div id='qr_code'>
                <img style={{ size: 'a5', margin: '0mm' }} alt='QR code' src={record.QRcode} />
              </div>
              <div
                style={{ cursor: 'pointer', margin: '5px 0px 15px 0px' }}
                onClick={() => copyToClipboardHandler(record.pairingURL)}
              >
                {record?.pairingURL?.slice(0, 30)}...
              </div>
              <Button onClick={() => printQR()}>PRINT</Button>
            </div>
          }
          title='QR code'
        >
          <img height='25px' width='25px' alt='QR code' src={record.QRcode} />
        </Popover>
      ),
    },
    {
      title: 'Bracelet URL',
      render: (text, record) => (
        <Popover
          content={<p>{record.pairingURL}</p>}
          title='Bracelet URL - Click to copy to clipboard'
          onClick={() => copyToClipboardHandler(record.pairingURL)}
        >
          <LinkOutlined title='Link' style={{ textDecoration: 'none', color: '#90989E' }} />
        </Popover>
      ),
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              placement='left'
              title={`This will delete patient`}
              onConfirm={() => deletePatientHandler(record._id)}
              okText='Ok'
              cancelText='Cancel'
            >
              <Trash title={`Delete patient`} />
            </Popconfirm>
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => getPatientForEdit(record._id)}
              title={`Edit patient`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Layout className='manage-layout'>
      <div
        style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        <HeaderComponent text='Manage patients' />
        <div className='teams-content'>
          <div className='teams-container'>
            <div className='teams-upper-part'>
              <div className='left-part'>
                <Input
                  className='inppy'
                  placeholder='Search'
                  onChange={(e) => handleSearch(e.target.value)}
                  prefix={<Magnifying style={{ marginRight: '5px' }} />}
                />
                <Button className='butty' type='primary' onClick={() => setNewPatient(true)}>
                  Add new patient
                </Button>
              </div>
              <div className='right-part'>
                <h3>Patient licenses:</h3>
                <h3 style={{ marginLeft: 10 }}>Total {currentuser?.data?.client?.totalPatientLicences}</h3>
                <h3 style={{ marginLeft: 10 }}>Used {currentuser?.data?.client?.licencedPatients?.length || 0}</h3>
              </div>
            </div>

            <div className='table-manage'>
              <Table
                size='middle'
                bordered
                dataSource={patients}
                columns={columns}
                locale={{ emptyText: <Empty description='No Patients, please add patient.' /> }}
                rowKey='_id'
                pagination={{
                  defaultPageSize: 20,
                  position: 'bottom',
                  showSizeChanger: false,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  hideOnSinglePage: false,
                }}
              />
            </div>
          </div>
        </div>
        {patientForEdit && (
          <div>
            <Modal open={patientForEdit} onCancel={() => setPatientForEdit(null)} footer={null} centered={true}>
              <div style={{ padding: '8px' }}>
                <div>
                  <PatientForm
                    isNew={false}
                    data={patientForEdit}
                    onSubmit={addAndEditPatientHandler}
                    branches={branches}
                    studies={studies}
                    qoolections={qoolections}
                    companyPrefix={currentuser?.data?.client?.companyPrefix}
                  />
                </div>
              </div>
            </Modal>
          </div>
        )}
        {newPatient && (
          <div>
            <Modal open={newPatient} onCancel={() => setNewPatient(null)} footer={null} centered={true}>
              <div style={{ padding: '10px 0px 40px 0px' }}>
                <div>
                  <PatientForm
                    isNew={true}
                    data={patientForEdit}
                    onSubmit={addAndEditPatientHandler}
                    studies={studies}
                    branches={branches}
                    qoolections={qoolections}
                    companyPrefix={currentuser?.data?.client?.companyPrefix}
                  />
                </div>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Patients;
